const timerF = function(stop){
  let seconds = 59;
  let secondsFinal = 10;

  let timer;
  let stopTimer = true;

  let textSeconds = $('.counter .minute');
  let textSecFinal = $('.time-alert strong');

  console.log(stopTimer);

  function timer60(e) {

    if (seconds < 59) {
      console.log('seconds');
      textSeconds.text(`00:${seconds}`);
    }

    if (seconds == 10) {

    }

    if (seconds > 0) {
      seconds --;
    } else {
      clearInterval(timer);
      console.log('finish');
    }
  }

  function timer10(e) {
    if (secondsFinal > 0) {
      secondsFinal--;
    } else {
      clearInterval(timer);
      console.log('finish');
    }
  }

  return timer;

  // if (stopTimer) {
  //   if (!timer && stop != 'stopAudio') {
  //     timer = setInterval(() => {
  //       timer60();
  //     }, 1000);
  //   } else {
  //     console.log(stop);
  //     stopTimer = false;
  //     clearInterval(timer);
  //     return;
  //   }
  // }

  // console.log(stopTimer);

  if (!stopTimer) {
    return;
  }

}

export default timerF;
