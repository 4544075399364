$(function(){

  let seconds = 59;
  let secon4 = 4;
  let secondsFinal = 9;

  let timer = 0;

  let textSeconds = $('.counter .minute');
  let textSecFinal = $('.time-alert strong');


  var videoMaxLengthInSeconds = 60;
  let buttonStart = $("#start-video");
  let buttonStop = $("#stop-video");
  let buttonFinish = $("#finish-video");


  function timer60(e) {

    if (seconds < 60) {
      textSeconds.text(`00:${seconds}`);
    }

    if (seconds < 10) {
      $('.time-alert').show();
      textSecFinal.text(`${secondsFinal} segundos`);

      if (secondsFinal > 0) {
        secondsFinal--;

      } else {
        clearInterval(secondsFinal);
      }
    }

    if (seconds > 0) {
      seconds--;
    } else {
      clearInterval(timer);
    }
  }

  // Inialize the video player
  var player = videojs("myVideo", {
    controls: false,
    width: 1000,
    height: 500,
    fluid: false,
    plugins: {
      record: {
        audio: true,
        video: true,
        maxLength: videoMaxLengthInSeconds,
        debug: true,
        videoMimeType: "video/webm;codecs=H264"
      }
    }
  }, function () {
    // print version information at startup
    videojs.log(
      'Using video.js', videojs.VERSION,
      'with videojs-record', videojs.getPluginVersion('record'),
      'and recordrtc', RecordRTC.version
    );
  });

  buttonStart.on('click', () => {

    let count;

    $('.overlay-count').fadeIn();

    count = setInterval(() => {
      if (secon4 > 1) {
        secon4--;
        $('.overlay-count').find('.num').text(`${secon4}`);
      } else {
        clearInterval(count);
        $('.overlay-count').fadeOut();
        secon4 = 4;

        $('.counter').fadeIn();
        textSeconds.text(`00:60`);

        if (!timer) {
          timer = setInterval(() => {
            timer60();
          }, 1000);
        }

        player.record().start();

      }
    }, 1000);

    $('.time-alert').hide();
    $('.note-hide').hide();

    buttonStart.hide();
    buttonStart.addClass('disabled');
    buttonStart.attr('disabled');

    buttonStop.show();
    buttonStop.removeClass('disabled');
    buttonStop.removeAttr('disabled');

  });

  buttonStop.on('click', ()=> {
    player.record().stop();

    buttonStart.show();
    buttonStart.removeClass('disabled');
    buttonStart.removeAttr('disabled');

    buttonStop.hide();
    buttonStop.addClass('disabled');
    buttonStop.attr('disabled');

    buttonFinish.removeClass('disabled');
    buttonFinish.removeAttr('disabled');
  });

  permissions();

  function permissions() {
    $(".vjs-record").click();
  }

  player.on('deviceReady', function () {
    buttonStart.removeClass('disabled');
    buttonStart.removeAttr('disabled');

  });

  // error handling for getUserMedia
  player.on('deviceError', function () {
    alert('error en el dispositivo o no aceptó el acceso a la camara o no cuanta con alguna:', player.deviceErrorCode);
  });

  // Handle error events of the video player
  player.on('error', function (element, error) {
    alert('error:', error);
  });


  // user clicked the record button and started recording !
  player.on('startRecord', function () {
    console.log('started recording! Do whatever you need to');
  });

  // buttonStart.on('click', function () {
  //   setTimeout(function () {
  //     $(".vjs-record-button").click();
  //   }, 2000);
  // });

  // user completed recording and stream is available
  player.on('finishRecord', function () {
    console.log('finished recording')

    clearInterval(timer);
    clearInterval(secondsFinal);
    seconds = 59;
    timer = 0;
    timer = 0;
    secon4 = 4;

    buttonStart.show();
    buttonStart.removeClass('disabled');
    buttonStart.removeAttr('disabled');

    buttonStop.hide();
    buttonStop.addClass('disabled');
    buttonStop.attr('disabled');

    buttonFinish.removeClass('disabled');
    buttonFinish.removeAttr('disabled');
  });

  buttonFinish.on('click', ()=> {
    $('#modal').fadeIn();
  });

  $('.btn-terms-fin').on('click', function () {

    let el = $(this);
    let val = el.data('value');

    // the blob object contains the recorded data that
    // can be downloaded by the user, stored on server etc.
    console.log('save recording: ', player.recordedData);

    // Create an instance of FormData and append the video parameter that
    // will be interpreted in the server as a file
    var formData = new FormData();
    formData.append('video', player.recordedData);
    formData.append('terms', val);

    let modal = $('#modal');
    // let modalTerms = modal.find('.terms');
    let modalLoad = modal.find('.load-data');
    let modalThanks = modal.find('.thanks');

    // Execute the ajax request, in this case we have a very simple PHP script
    // that accepts and save the uploaded "video" file
    xhr('/dejanostupregunta/public/video/store', formData, function (fName) {
      console.log("Video succesfully uploaded !");

      setTimeout(() => {
        modalLoad.hide();
        modalThanks.fadeIn();
      }, 1000);
    });

    // Helper function to send
    function xhr(url, data, callback) {
      var request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState == 4 && request.status == 200) {
          callback(location.href + request.responseText);
        }
      };
      request.open('POST', url);
      request.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));
      request.send(data);
    }
  });

});
