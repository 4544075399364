$(function () {

  let home = $('#home');
  let mainVideo = home.find('.main-video');
  let overlay = mainVideo.find('.overlay');
  let icoPlay = mainVideo.find('.ico-play');
  let btnQuestion = $('#btn-question');

  icoPlay.on('click', () => {
    overlay.fadeOut();
    btnQuestion.css('display', 'block');

    $(".iframeYTB")[0].src += "&autoplay=1";
    // $('.iframeYTB')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
  });

});
