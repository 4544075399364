// let timerF = require('./timer');
// import timerF from './timer';


$(function(){

  let seconds = 59;
  let secondsFinal = 9;

  let timer = 0;

  let textSeconds = $('.counter .minute');
  let textSecFinal = $('.time-alert strong');

  let btnRecordAudio = $('.btn-record-audio');
  let btnStopAudio = $('.btn-stop-audio');;
  let btnPlayAudio = $('.btn-play-audio');;

  var buttonFinish = $("#finish-audio");
  /* eslint-disable */
  var options = {
    controls: false,
    width: 420,
    height: 185,
    fluid: false,
    plugins: {
      wavesurfer: {
        src: 'live',
        waveColor: '#fff',
        progressColor: 'black',
        debug: true,
        cursorWidth: 1,
        msDisplayMax: 20,
        hideScrollbar: true
      },
      record: {
        audio: true,
        video: false,
        maxLength: 60,
        debug: true
      }
    }
  };

  function timer60(e) {

    if (seconds < 60) {
      textSeconds.text(`00:${seconds}`);
    }

    if (seconds < 10) {
      $('.time-alert').show();
      textSecFinal.text(`${secondsFinal} segundos`);

      if (secondsFinal > 0) {
        secondsFinal--;

      } else {
        clearInterval(secondsFinal);
      }
    }

    if (seconds > 0) {
      seconds--;

    } else {
      clearInterval(timer);
    }
  }

  // apply audio workarounds for certain browsers
  applyAudioWorkaround();

  // create player
  var player = videojs('myAudio', options, function () {
    // print version information at startup
    var msg = 'Using video.js ' + videojs.VERSION +
      ' with videojs-record ' + videojs.getPluginVersion('record') +
      ', videojs-wavesurfer ' + videojs.getPluginVersion('wavesurfer') +
      ', wavesurfer.js ' + WaveSurfer.VERSION + ' and recordrtc ' +
      RecordRTC.version;
    videojs.log(msg);
  });

  btnRecordAudio.on('click', ()=> {

    textSeconds.text(`00:60`);

    if (!timer) {
      timer = setInterval(() => {
        timer60();
      }, 1000);
    }

    $('.time-alert').hide();

    btnRecordAudio.addClass('disabled');
    btnStopAudio.removeClass('disabled');
    btnPlayAudio.addClass('disabled');

    player.record().start();

  });;

  btnStopAudio.on('click', () => {
    console.log('´play');
    player.record().stop();

    btnRecordAudio.removeClass('disabled');
    btnStopAudio.addClass('disabled');
    btnPlayAudio.removeClass('disabled');
  });;

  btnPlayAudio.on('click', () => {
    // player.record().resume();
    $('.vjs-play-control').click();
  });;

  // permissions();
  function permissions() {
    $(".vjs-icon-audio-perm").click();
  }

  player.on('deviceReady', function () {
    btnRecordAudio.removeClass('disabled');
  });

  // error handling
  player.on('deviceError', function () {
    console.log('device error:', player.deviceErrorCode);
    $('.btn-audio').addClass('disabled');
    alert('Error en el dispositivo o canceló el acceso al micrófono');
  });

  player.on('error', function (element, error) {
    console.error(error);
  });

  // user clicked the record button and started recording
  player.on('startRecord', function () {
    console.log('started recording!');
  });

  player.on('stopRecord', function () {
    console.log('stopRecord recording!');
  });

  // user completed recording and stream is available
  player.on('finishRecord', function () {
    // the blob object contains the recorded data that
    // can be downloaded by the user, stored on server etc.

    clearInterval(timer);
    clearInterval(secondsFinal);
    seconds = 59;
    secondsFinal = 9;
    timer = 0;

    btnRecordAudio.removeClass('disabled');
    btnStopAudio.addClass('disabled');
    btnPlayAudio.removeClass('disabled');

    buttonFinish.removeClass('disabled');
    buttonFinish.removeAttr('disabled');
  });

  // setTimeout(function () {
  //   $('.vjs-device-button').click();
  // }, 1000);

  buttonFinish.on('click', ()=> {
    $('#modal').fadeIn();
  });

  $('.btn-terms-fin').on('click', function (e) {

    let el = $(this);
    let val = el.data('value');

    // the blob object contains the recorded data that
    // can be downloaded by the user, stored on server etc.
    console.log('save recording: ', player.recordedData);

    // Create an instance of FormData and append the video parameter that
    // will be interpreted in the server as a file
    var formData = new FormData();
    formData.append('video', player.recordedData);
    formData.append('terms', val);


    let modal = $('#modal');
    // let modalTerms = modal.find('.terms');
    let modalLoad = modal.find('.load-data');
    let modalThanks = modal.find('.thanks');

    // Execute the ajax request, in this case we have a very simple PHP script
    // that accepts and save the uploaded "video" file
    xhr('/dejanostupregunta/public/video/store', formData, function (fName) {
      console.log("Video succesfully uploaded !");

      setTimeout(() => {
        modalLoad.hide();
        modalThanks.fadeIn();
      }, 1000);

    });

    // Helper function to send
    function xhr(url, data, callback) {
      var request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState == 4 && request.status == 200) {
          callback(location.href + request.responseText);
        }
      };
      request.open('POST', url);
      request.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));
      request.send(data);
    }
  });

});
